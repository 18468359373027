<script>
  export let size = 32;
  let className = "";
  export { className as class };
</script>

<svg
  width={size}
  height={size}
  class={className}
  viewBox="0 0 32 32"
  fill="currentColor"
  stroke="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="settings-2">
    <g id="Group 7">
      <path
        id="Vector"
        d="M28 7L5 7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M30.25 7C30.25 8.79493 28.7949 10.25 27 10.25C25.2051 10.25 23.75 8.79493 23.75 7C23.75 5.20507 25.2051 3.75 27 3.75C28.7949 3.75 30.25 5.20507 30.25 7Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <g id="Group 6">
      <path
        id="Vector_3"
        d="M27 16L4 16"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M7.66667 16C7.66667 17.4728 6.47276 18.6667 5 18.6667C3.52724 18.6667 2.33333 17.4728 2.33333 16C2.33333 14.5272 3.52724 13.3333 5 13.3333C6.47276 13.3333 7.66667 14.5272 7.66667 16Z"
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <g id="Group 5">
      <path
        id="Vector_5"
        d="M28 25L5 25"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M29.6667 25C29.6667 26.4728 28.4728 27.6667 27 27.6667C25.5272 27.6667 24.3333 26.4728 24.3333 25C24.3333 23.5272 25.5272 22.3333 27 22.3333C28.4728 22.3333 29.6667 23.5272 29.6667 25Z"
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </g>
</svg>
